import {compose, withState} from 'proppy';

const UxState = compose(
	withState('noFilter', 'setNoFilter', false),
	withState('hide', 'setHide', []),
 	withState('lang', 'setLang', 'de'),
	withState('filterChecked','setFilterChecked','none'))

const ux = UxState();

export {
	ux
};
