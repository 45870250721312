import { Component } from 'hyperhtml';
import { zt } from '../store/ZoteroState';
import { ux } from '../store/UxState';
import { qr } from '../store/QueryState';

import TagFilterItem from './tagFilterItem';
import ItemTypes from './itemTypes';
import ItemTypeFilterItem from './itemTypeFilterItem';
import Search from './search';
import SearchCreator from './searchCreator';
import Reset from './reset';
import Interval from './interval';
import ReviewedFilterItem from './reviewedFilterItem';
import ResearchGroups from './researchGroups';
import Sort from './sort';
import SpecialFilterItem from './specialFilterItem';
import TagFilter from './tagFilter';
import listTypeFilter from './listTypeFilter';

class Filter extends Component {
  constructor() {
    super();

    this.tagFilterItem = new TagFilterItem();
    this.tagFilter = new TagFilter();
    this.itemTypeFilterItem = new ItemTypeFilterItem();
    this.search = new Search();
    this.searchCreator = new SearchCreator();
    this.reset = new Reset();
    this.interval = new Interval();
    this.researchGroups = new ResearchGroups();
    this.itemTypes = new ItemTypes();
    this.listTypeFilter = new listTypeFilter();
    this.sort = new Sort();
    this.reviewedFilterItem = new ReviewedFilterItem({
      label: 'Begutachtete Publikationen',
    });
    this.query = qr.props.query;
    zt.subscribe((pr) => {
      this.render();
    });
    ux.subscribe((pr) => {
      this.render();
    });

    const thisSearch = document.querySelector('[data-wzb-component="Zotero"]');

    this.filterType = thisSearch.getAttribute('data-wzb-type');

    console.log(this.query);
  }

  render() {
    var language = window.location.href;
    var languageEnglish = language.includes('/en/') ? true : false;
    var publicationsFoundLabel = '';
    switch (ux.props.filterChecked) {
      case 'publications':
        publicationsFoundLabel = languageEnglish
          ? 'publications found'
          : 'Publikationen gefunden';
        break;
      case 'lectures':
        publicationsFoundLabel = languageEnglish
          ? 'lectures and presentations found'
          : 'Vorträge und Präsentationen gefunden';
        break;
      case 'transfer':
        publicationsFoundLabel = languageEnglish
          ? ' transfer-oriented Publications found'
          : 'Transferorientierte Veröffentlichungen gefunden';
        break;
      default:
        var publicationsFoundLabel = languageEnglish
          ? 'publications found'
          : 'Publikationen gefunden';
        break;
    }
    switch (this.filterType) {
      case 'person':
        if (this.query.tags[0] != 'forschung')
          this.query.tags.push('forschung');
        return ux.props.noFilter
          ? this
              .html`<div class="wzb-filteredArticles__filters wzb-filters wzb-filters--publications">
									</div>
									`
          : this.html`
									<div class="m-filteredarticles">
										<div class="container">
											<div class="row">
											${this.search}
											${this.searchCreator}
											${this.reset}
											<div class="m-filteredarticles__filters">
												${this.interval}
												${this.listTypeFilter}
											</div>
											<div class="m-filteredarticles__itemCount">${zt.props.total} ${publicationsFoundLabel}</div>
											${this.sort}
											</div>
										</div>
									</div>
									`;
      default:
        return ux.props.noFilter
          ? this
              .html`<div class="wzb-filteredArticles__filters wzb-filters wzb-filters--publications">
					</div>
					`
          : this.html`
					<div class="m-filteredarticles">
						<div class="container">
							<div class="row">
							${this.search}
							${this.searchCreator}
							${this.reset}
							<div class="m-filteredarticles__filters">
								${this.interval}
								${this.listTypeFilter}
							</div>
							<div class="m-filteredarticles__itemCount">${zt.props.total} ${publicationsFoundLabel}</div>
							${this.sort}
							</div>
						</div>
					</div>
					`;
    }
  }
}

export default Filter;
